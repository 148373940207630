import React from 'react';
import styled from 'styled-components';

import { black } from 'styles/colors';

const BodyWrapperStyled = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: ${black};
  overflow-x: visible;

  a {
    text-decoration: none;
    transition: opacity 0.1;

    &:hover {
      opacity: 0.8;
    }

    &:any-link {
      color: inherit;
    }
  }
`;

export const BodyWrapper = props => (
  <BodyWrapperStyled id="body-wrapper">{props.children}</BodyWrapperStyled>
);
