import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import { Quiz } from 'types/quiz';
import Logo from 'assets/icons/logo.svg';
import Fb from 'assets/icons/quiz/facebook.svg';
import Ig from 'assets/icons/quiz/instagram.svg';
import { config } from 'config';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const StyledLogo = styled(Logo)`
  width: 2.69425rem;
  height: 1.625rem;
  @media ${tablet} {
    width: 2.9015rem;
    height: 1.75rem;
  }
`;

const LCFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const {
    goToPrivacy,
    goToTerms,
    goToContact,
    goToLanding,
    goToFAQ,
    goToReviews,
  } = useRouter();
  const { isTablet } = useQuery();
  //
  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToLanding()}>
              <StyledLogo />
            </LinkWrap>
            {/* <LinkWrap onClick={() => goToReviews()}>
              <StyledTextT>Reviews</StyledTextT>
            </LinkWrap> */}
            <LinkWrap>
              <StyledTextT type="bodyM600" color="light0">
                {/* TO DO */}
                <StyledLink href="https://help.no.diet/hc/en-us">
                  Help Center
                </StyledLink>
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToContact()}>
              <StyledTextT>Contact Us</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT>Terms & Conditions</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT>Privacy Policy</StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              <IconsContainer>
                <a
                  href="https://www.facebook.com/profile.php?id=61570040494869"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fb />
                </a>
                <a
                  href="https://www.instagram.com/no.diet.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ig />
                </a>
              </IconsContainer>
              {disclaimerText.map((paragraph, index) => (
                <StyledText key={index}>{paragraph}</StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default LCFooter;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.75rem;
  }
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledLink = styled.a`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  gap: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledText = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
`;

const StyledTextBold = styled(StyledTextT)`
  font-weight: 600 !important;
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const Container = styled.div<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 0;
  gap: 1.5rem;
  background: ${({ whiteFooter }) => (whiteFooter ? '#fff' : '#F5F5F5')};
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1.75rem 1rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media ${tablet} {
    flex-direction: column;
    height: unset;
    gap: 1rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;
