import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  name?: string;
  title?: string;
  description?: string;
}

export const Seo: React.FC<Props> = ({
  name = '#1 Weight Management Plan | no.Diet',
  title = '#1 Weight Management Plan | no.Diet',
  description = 'Fully personalized meal plan to help you reach your body goals easily.',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />

    {/* FB tags */}
    <meta property="og:url" content="https://no.diet" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
