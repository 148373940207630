import React, { FC } from 'react';
import styled from 'styled-components';

import theme from 'utils/theme';
import { Loader } from './Loader';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const LoadingPage: FC = () => (
  <Container>
    <Loader height={52} width={52} />
  </Container>
);
