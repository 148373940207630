import React, { ComponentPropsWithoutRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';
import RightArrow from 'assets/icons/quiz/right-arrow.svg';
import Checked from 'assets/icons/quiz/checked-checkbox.svg';
import Empty from 'assets/icons/quiz/empty-checkbox.svg';
import Single from 'assets/icons/quiz-arrow.svg';
import quizSvgs from 'utils/constants/quizSvgs';
import { DynamicImage } from './images/DynamicImage';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  isMultiple?: boolean;
  emoji?: string;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  animate: boolean;
}>`
  background: ${({ active }) => (active ? '#EDF8EC' : '#FFF')};
  border: 1px solid ${({ active }) => (active ? '#4CB944' : '#EDEDED')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  height: 4rem;

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};
  box-shadow: 0px 2px 4px 1px rgba(29, 36, 48, 0.03);
  border-radius: 0.75rem;
  padding: 1rem 0.75rem;
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
`;

const ButtonText = styled(Text)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
`;

const Inner = styled.section`
  display: flex;
  gap: 0.75rem;
`;
const CheckedIcon = styled(Checked)<{ active: boolean }>`
  transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0.7)')};
  transition: transform 0.3s ease-out;
  path {
    stroke: ${({ active }) => (active ? '#fff' : '#fff')};
  }
`;

const CheckedSvg = styled.section<{ active: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  background: ${({ active }) => (active ? '#4CB944' : '#fff')};
  border: 1.2px solid ${({ active }) => (active ? '#4CB944' : '#EDEDED')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  isMultiple,
  emoji,
  ...props
}) => {
  const [animate, setAnimate] = useState(false);

  const handleMouseDown = () => {
    setAnimate(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimate(false);
    }, 100);
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Container
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      active={active}
      maxSelected={maxSelected}
      as="button"
      animate={animate}
      {...props}
    >
      <Inner>
        {isMultiple && (
          <CheckedSvg active={active as boolean}>
            <CheckedIcon active={active as boolean} />
          </CheckedSvg>
        )}
        <ButtonText>{children}</ButtonText>
      </Inner>
      {!isMultiple && !emoji && <RightArrow />}
      {emoji && (
        <EmojiContainer>
          <Emoji src={emoji} alt="emoji" />
        </EmojiContainer>
      )}
    </Container>
  );
};

export default React.memo(QuizAnswerButton);

const EmojiContainer = styled.section`
  width: 2.5rem;
  height: 2.5rem;
`;

const Emoji = styled(DynamicImage)`
  width: 2.5rem;
  height: 2.5rem;
`;
