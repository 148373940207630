import { Seo } from 'components';
import React from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { Analytics } from 'apis/Analytics';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import Tracking from 'utils/tracking';

enum QuestionType {
  Single = 'single',
  Multiple = 'multiple',
  MultipleImages = 'multiple_images',
  Encouragement = 'encouragement',
  Testimonial = 'testimonial',
  Info = 'info',
  Landing = 'landing',
  Landing2 = 'landing2',
  Image = 'image',
  Slider = 'slider',
  Scale = 'scale',
  Input = 'input',
  PreviousMultiple = 'previous_multiple',
  Insert = 'insert',
  Cost = 'cost',
}

const Home: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const questions = useSelector((state: AppState) => state.funnel.questions);
  const question = questions[0];
  const { goToQuizStart } = useRouter();
  const quiz = new URLSearchParams(location.search).get('lp') ?? QuizType.Main;
  const q = new URLSearchParams(location.search).get('q') ?? '1';

  const logEvent = (data: { category: string; label?: string }) => {
    Tracking.logEvent({
      event: `Quiz question answered - ${quiz}`,
      category: `${q} - ${data.category}`,
      label: data.label,
    });
  };

  const handleLandingOptionSelect = (answer: QuizAnswer) => {
    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(answer.label),
    });
    delete answer.label;
    dispatch(updateQuizAnswers(answer));
    goToQuizStart();
  };
  return (
    <>
      <Seo />
      {question?.type === QuestionType.Landing ? (
        <Landing
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )}
    </>
  );
});

Home.displayName = 'Home';

export default Home;
