import React from 'react';
import styled from 'styled-components';
// import SpinnerSvg from 'assets/icons/circle-loader.svg';
import Laoder from 'assets/icons/loading-circle.svg';
import theme from 'utils/theme';

interface ILoader {
  height?: number;
  width?: number;
  wrapperHeight?: string;
}
export const Loader: React.FC<ILoader> = ({
  height = 52,
  width = 52,
  wrapperHeight = '30vh',
}) => (
  <OuterWrapper height={wrapperHeight}>
    <LoaderWrapper height={height} width={width}>
      <Laoder />
    </LoaderWrapper>
  </OuterWrapper>
);

const OuterWrapper = styled.div<{ height: string }>`
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled(Laoder)<{
  color: string;
  height: number;
  width: number;
}>`
  height: 52px;
  width: 52px;
`;
