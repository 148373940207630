import { useMediaQuery } from 'react-responsive';

export const sxMobile = '(max-width: 320px)'; // 352px
export const smMobile = '(max-width: 22em)'; // 352px
export const mMobile = '(max-width: 391px)'; // 352px
export const mobile = '(max-width: 30em)'; // 480px
export const tablet = '(max-width: 48em)'; // 768px
export const laptop = '(max-width: 63.9375rem)'; // 1023px
export const smLaptop = '(max-width: 70.88em)'; // 1134px
export const desktop = '(max-width: 1440px)'; // 1134px

export const useQuery = () => ({
  isSxMobile: useMediaQuery({ query: sxMobile }),
  isSmMobile: useMediaQuery({ query: smMobile }),
  isMMobile: useMediaQuery({ query: mMobile }),
  isMobile: useMediaQuery({ query: mobile }),
  isTablet: useMediaQuery({ query: tablet }),
  isLaptop: useMediaQuery({ query: laptop }),
});
